import React, { useCallback, useEffect, useRef, useState } from 'react';

// 3RD PARTY
import './app/ui-core/i18n';
import { Switch, Route, useLocation, Redirect, useHistory } from 'react-router-dom';
import { SanitasProvider } from '@quillbot/keralty-sanitas-chatbot';

// UI
import './App.scss';
import Chat from './app/components/organisms/chat/Chat';


import SanitasChat, {
  ChangeVisibility,
  SanitasChatRef,
} from './app/components/organisms/sanitasChat/sanitasChat';

// UI - PAGES
import FormSSO from 'app/screens/FormSSO/FormSSO';
import MainPage from './app/screens/MainPage';
import ChatPage from './app/screens/ChatPage';
import ForgotPasswordPage from 'app/screens/ForgotPasswordPage';
import UnblockAccountPage from 'app/screens/UnblockAccountPage';
import LocationSelect from './app/screens/locationSelect/LocationSelect';
import LoginPage from './app/screens/login/LoginPage';
import PatientRegistrationPage from './app/screens/patientRegistration/PatientRegistrationPage';
import LegalPage from './app/screens/LegalPage';
import HandlerSSO from './app/screens/HandlerSSO';
import NotFoundPage from './app/screens/NotFoundPage';
import ImgModal3 from './app/assets/icons/circle-exclamation.svg';
// LOGIC
import PrivateRoute from './app/components/organisms/PrivateRoute';
import useAuth from './app/hooks/useAuth';
import SessionTimeout from './app/components/SessionTimeout';
import {
  PatientRegistrationGuard,
  LocationGuard,
  AuthGuard,
  ForgotPasswordGuard,
  UnblockAccountGuard,
} from './app/guards';
// ROUTES
import ROUTES from './app/ui-core/utils/routes';
import HomePage from './app/screens/NewHomePage';
import MyAccountMain from './app/screens/MyAccount/MyAccountMain';
import MyHealthMain from './app/screens/MyHealth/MyHealthMain';
import GetCareMain from './app/screens/GetCare/GetCareMain';

import appConfig from 'config';
import Register from './app/screens/register/Register';

import i18n from 'i18next';
import config from './app/components/organisms/sanitasChat/configChat';
import MessagesEs from './app/components/organisms/sanitasChat/Message.es.json';
import MessagesEn from './app/components/organisms/sanitasChat/Message.en.json';
import VerifyOptionFirst from 'app/components/organisms/forgotPassword/VerifyOptionFirst';
import MentalHealthMain from 'app/screens/Mentalhealth/MentalHealth';
import { SESSION_VARIABLE } from 'app/ui-core/utils/sessionStorage';
import Maintenance from 'app/components/Maintenance';
import { Authentication } from 'app/screens/authentication';
import WellnessPage from 'app/screens/wellness/WellnessPage';

import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { KeraltyChat, KeraltyChatComponent } from '@quillbot/keralty-chat-lit';
import { utcTime } from 'app/ui-core/utils/times';
import { ComponentChat } from 'app/components/organisms/login/loginLayout/login.styled';
import { useAppSelector } from 'adapter/hooks';
import { selectNewChatKeralty, setKeraltChatAction } from 'adapter/sanitasChat/sanitasChat';
import { useDispatch } from 'react-redux';
import { userActions } from 'adapter/user/userSlice';
import useIsMobile from 'app/hooks/useIsMobile';
import useGeneralErrors from 'app/hooks/useGeneralErrors';
import { KeraltyButton, KeraltyText } from 'app/components/atoms';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import styled from 'app/ui-core/styled-components';
import { Column } from 'app/components/templates';
import { useTranslation } from 'react-i18next';
import { ROOM_STATUS } from 'app/ui-core/utils/constants';
import useMaintenanceAndNotifications from 'app/hooks/useMaintenanceAndNotifications';

const { MAIN, REGISTER, PATIENT_REGISTRATION } = ROUTES;
const { CON_CHAT } = appConfig;

const ContainModal = styled(Column)`
	position: fixed;
	display: flex;
	justify-content: center;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	overflow: auto;
	z-index: 999;
	background-color: #00000058;
	width: 100%;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		align-items: center;
	}
`;
const Modal = styled(Column)`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 20px;
	padding: 10px;
	width: 40%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 15% 5% 0% 5%;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	}
`;

const App = () => {
  const location = useLocation();
  const { isLoggedIn, isLoggedIn: isAuthenticated, user } = useAuth();

  const stateNewChat = useAppSelector(selectNewChatKeralty);
  const dispatch = useDispatch();
  const isOpeningChatFromApp = location.pathname === '/chat';
  const sanitasChatRef = useRef<SanitasChatRef>(null);
  const lang = i18n.language?.includes('en') ? 'en' : 'es';
  const [isLoggedChat, setIsLoggedChat] = useState(false);
  const history = useHistory();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { getMaintenanceAndNotification } =  useMaintenanceAndNotifications();

  const { Errors, setGeneralError } = useGeneralErrors();

  // ¡¡¡¡ IMPORTANT !!!!
  // EACH NESTED <Switch></Switch> MUST HAVE THIS CONTENT
  // <Switch> => PARENT PATH => '/fb'
  // ...Routes LIKE <PrivateRoute path component|render />
  // + optional? A DEFAULT REDIRECT ROUTE FOR '/fb' LIKE '/fb/some-route'
  // + required* A REDIRECT ROUTE FOR NOT FOUND ROUTES LIKE '/not-found'
  // </Switch>

  const funcionEjecutada = useRef(false);

  useEffect(() => {
	  if (!funcionEjecutada.current) {
		  getMaintenanceAndNotification();
		  funcionEjecutada.current = true;
	  }
  }, [])

  useEffect(() => {
    if (!location.pathname.includes(MAIN.mentalHealth))
      KeraltySWS?.swInstance?.deleteItem(SESSION_VARIABLE.HIDDEN_DISCLAIMER);
  }, [location.pathname]);

  const closeChat = async () => {
	if(!user.isActivedChat) return;

	await useRefChat?.current?.closeChatScreen();
  }

  useEffect(() => {
	closeChat();
  }, [isMobile]);

  const PatientRegistrationFBRoutes = (
    <Switch>
      <PrivateRoute
        path={`/${MAIN.fb}/${MAIN.patientRegistration}/:step`}
        authenticated={isLoggedIn}
        component={PatientRegistrationPage}
        guards={[PatientRegistrationGuard]}
      />
      {/* DEFAULT */}
      <PrivateRoute
        exact
        path={`/${MAIN.fb}/${MAIN.patientRegistration}`}
        redirectTo={`/${MAIN.fb}/${MAIN.patientRegistration}/${PATIENT_REGISTRATION.responsible}`}
      />
      {/* NOT MATCH */}
      <PrivateRoute redirectTo={`/${MAIN.notFound}`} />
    </Switch>
  );

  const PatientRegistrationRoutes = (
    <Switch>
      <PrivateRoute
        path={`/${MAIN.patientRegistration}/:step`}
        component={PatientRegistrationPage}
        guards={[PatientRegistrationGuard]}
      />
      {/* DEFAULT */}
      <PrivateRoute
        exact
        path={`/${MAIN.patientRegistration}`}
        redirectTo={`/${MAIN.patientRegistration}/${PATIENT_REGISTRATION.responsible}`}
      />
      {/* NOT MATCH */}
      <PrivateRoute redirectTo={`/${MAIN.notFound}`} />
    </Switch>
  );

  const onHide = useCallback(() => {
    ChangeVisibility('hidden');
    setTimeout(async () => {
      if (sanitasChatRef) {
        sanitasChatRef?.current?.onHideChat();
      }
    });
  }, []);

  const onShow = useCallback(() => {
    setTimeout(async () => {
      ChangeVisibility('visible');
    });
  }, []);

  const useRefChat: any = useRef(null);
  useEffect(() => {
    useRefChat.current = new KeraltyChat();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setTimeout(() => {
        useRefChat?.current?.loginUser({
          user: `${user.displayName}`,
          email: `${user.email}`,
          birthdaydate: `${user.birthdate}`,
          birthdate: `${user.birthdate}`,
          state: `${user.state}`,
          authuid: `${user.authUid}`,
          sanitasaccountnumber: `${user.idEcw}`,
          gender: `${user.sex}`,
          currenttimezone: `${utcTime()}`,
          //      token: `${user.token}`,
        });
        setIsLoggedChat(false);
      }, 4000);

      /*  setTimeout(() => {
        useRefChat.current.openChat('support');
      }, 3000); */
    } else {
		useRefChat?.current?.logoutUser();
	}
  }, [isLoggedIn]);

  /**
   * Effect to reload chat
   */
  useEffect(() => {
	const phoneMediaQuery = window.matchMedia('(min-width: 1022px)');

	phoneMediaQuery.addEventListener('change', (event) => {
		window.location.reload();
	})
  }, [])


  const openChat = async (queue: string) => {
    setTimeout(async () => {
      useRefChat?.current?.openChat(queue);
    }, 1200);
  };

  useEffect(() => {
    if (stateNewChat.state) {
      openChat(stateNewChat.queue);
      dispatch(setKeraltChatAction({ queue: '', state: false }));
    }
  }, [stateNewChat.queue, stateNewChat.state]);

  const ModalChatInProgress = () => {
    return (
      <ContainModal noPadding>
        {true && (
          <Modal style={{ width: isMobile ? "80%" : "30%"}}>
            <div style={{ padding: '10px' }}>
              <img src={ImgModal3} alt="" width={42} />
            </div>
            <div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
              <KeraltyText
                color="#0069A7"
                type="info"
                align="center"
                fontSize="20px"
                bold={false}
                fontWeight="400"
                fontFamily="Nova"
                style={{ fontWeight: 400, lineHeight: '24px' }}
              >
                <span dangerouslySetInnerHTML={{ __html: isMobile ? t('errors.troubleWithChat').replace("<br/>","") : t('errors.troubleWithChat') }}/>
              </KeraltyText>
            </div>
            <div>
              <KeraltyButton
                type={'submit'}
                full
                onClick={() => dispatch(userActions.setGeneralError({ error: "" }))}
                style={{ fontWeight: 400, width: 'auto' }}
              >
                {t('button.close')}
              </KeraltyButton>
            </div>
          </Modal>
        )}
      </ContainModal>
    );
  };

  return (
    <div className="App">
	  { user.generalError?.error === Errors.CHAT_IN_PROGRESS && <ModalChatInProgress />}
      <SanitasChat ref={sanitasChatRef} />
      <Switch>
        <Route path={`/${MAIN.selectLocation}`} component={LocationSelect} />
        <Route path={`/${MAIN.login}`} component={LoginPage} />
        <Route path={`/${MAIN.sso}`} component={HandlerSSO} />
        <Route path={`/${MAIN.legal}:tab?`} component={LegalPage} />
        <Route path={`/${MAIN.chat}`} component={ChatPage} />

        <Route path={`/${MAIN.unblock}/:step?`}>
          <UnblockAccountGuard>
            <UnblockAccountPage />
          </UnblockAccountGuard>
        </Route>

        {/* PREVENT OLDER REGISTER ROUTES */}

        <Route path={`/${MAIN.forgot}/:step?`}>
          <ForgotPasswordGuard>
            <ForgotPasswordPage />
          </ForgotPasswordGuard>
        </Route>

        {/* PREVENT OLDER REGISTER ROUTES */}

        <Route path={`/${MAIN.ssoForm}/:step?`}>
          <FormSSO />
        </Route>

        {/* PREVENT OLDER REGISTER ROUTES */}
        <Route
          path={`/${MAIN.fb}/${MAIN.register}/:step?`}
          render={({ location: { search, pathname } }) => {
            const fbStep = pathname.split('/').pop();

            return (
              <Redirect
                to={{
                  pathname: `/${MAIN.register}/${REGISTER.fb}${
                    fbStep !== MAIN.register ? `/${fbStep}` : ''
                  }`,
                  search,
                }}
              />
            );
          }}
        />
        {/* REGISTER */}
        <Route path={`/${MAIN.register}/:flow?/:step?`} component={Register} />
        <Route path={`/${MAIN.authentication}`} component={Authentication} />

        <PrivateRoute path={`/${MAIN.patientRegistration}`} guards={[PatientRegistrationGuard]}>
          {PatientRegistrationRoutes}
        </PrivateRoute>
        <PrivateRoute
          path={`/${MAIN.fb}/${MAIN.patientRegistration}`}
          guards={[PatientRegistrationGuard]}
        >
          {PatientRegistrationFBRoutes}
        </PrivateRoute>

        <Route path="/">
          {/* <LocationGuard> */}
          <AuthGuard>
            <MainPage>
              <Switch>
                <Route path={`/${MAIN.home}`} component={HomePage} />
                <Route path={`/${MAIN.myHealth}/:tab?`} component={MyHealthMain} />

                <Route path={`/${MAIN.getCare}/:tab?/:subTab?`} component={GetCareMain} />
                <Route path={`/${MAIN.myAccount}/:tab?`} component={MyAccountMain} />
                <Route
                  path={`/${MAIN.mentalHealth}/:tab?/:subTab?/:subSubTab?`}
                  component={MentalHealthMain}
                />
                <Route path={`/${MAIN.wellness}`} component={WellnessPage} />

                <Route exact path="/">
                  <Redirect to={`/${MAIN.home}`} />
                </Route>
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </MainPage>
          </AuthGuard>
          {/* </LocationGuard> */}
        </Route>

        <Route path="*" component={NotFoundPage} />
      </Switch>
      {!isOpeningChatFromApp && <Chat />}
      <SessionTimeout />
      <Maintenance />
      {
	  	[
			'/legal/privacy-policy',
			'/legal/terms-of-service',
			'/login'
		].includes(location.pathname) ? (
        <ComponentChat>
          <KeraltyChatComponent
            url={CON_CHAT}
            urlsocket={CON_CHAT}
            language={lang}
            ref={useRefChat}
            currentStatus={(event) => {
              const customEvent = event as CustomEvent;
              dispatch(userActions.setProp({ key: 'isActivedChat', value: customEvent?.detail?.isChatActive }));
            }}
			chatStatus={(event) => {
				const customEvent = event as CustomEvent;
				if(customEvent.detail?.res === ROOM_STATUS.TROUBLE_WITH_CHAT) {
					setGeneralError(Errors.CHAT_IN_PROGRESS)
				}
			}}
			isHiddenIconBurger={true}
          />
        </ComponentChat>
      ) : (
        <div
          style={{
            zIndex: 20,
            position: 'fixed',
            background: 'transparent',
            bottom: 0,
            right: user?.isActivedChat ? 0 : 15,
          }}
        >
          <KeraltyChatComponent
            url={CON_CHAT}
            urlsocket={CON_CHAT}
            language={lang}
            ref={useRefChat}
            currentStatus={(event) => {
              const customEvent = event as CustomEvent;
              dispatch(userActions.setProp({ key: 'isActivedChat', value: customEvent?.detail?.isChatActive }));
            }}
			chatStatus={(event) => {
				const customEvent = event as CustomEvent;
				if(customEvent.detail?.res === ROOM_STATUS.TROUBLE_WITH_CHAT) {
					setGeneralError(Errors.CHAT_IN_PROGRESS)
				}
			}}
            //    isHiddenIconBurger={history.location.pathname === '/my-account/edit-account'}
          />
        </div>
      )}
    </div>
    // </SanitasProvider>
  );
}

export default App;

declare global {
  interface Window {
    dataLayer?: any;
    KERALTY_CONFIG?: any;
  }
}
