import React, { useEffect, useState } from 'react';
import '../FormSSO/formSSO.css';
import appConfig from 'config';


const FormSSO: React.FC = () => {
  const [memberId, setMemberId] = useState('');
  const [memberName, setMemberName] = useState('');
  const [memberLastname, setMemberLastname] = useState('');
  const [urlNavigation, saveUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);

  const xmlTemplate = `<?xml version="1.0" encoding="UTF-8"?>
  <samlp2:Response Version="2.0" ID="SAML-f1010a20-b297-4e55-a933-16b553be57b0" IssueInstant="2021-09-24T14:42:28Z" Destination="https://chfed.mysanitas.com/sp/ACS.saml2" xmlns:samlp2="urn:oasis:names:tc:SAML:2.0:protocol">
    <saml2:Issuer xmlns:saml2="urn:oasis:names:tc:SAML:2.0:assertion">https://ssoportal-stga.bcbsfl.com/sso/saml2/Member/Sanitas</saml2:Issuer>
    <Signature xmlns="http://www.w3.org/2000/09/xmldsig#">
      <SignedInfo>
        <CanonicalizationMethod Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#"/>
        <SignatureMethod Algorithm="http://www.w3.org/2000/09/xmldsig#rsa-sha1"/>
        <Reference URI="#SAML-f1010a20-b297-4e55-a933-16b553be57b0">
          <Transforms>
            <Transform Algorithm="http://www.w3.org/2000/09/xmldsig#enveloped-signature"/>
            <Transform Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#"/>
          </Transforms>
          <DigestMethod Algorithm="http://www.w3.org/2000/09/xmldsig#sha1"/>
          <DigestValue>TV+UTLZ7+h1OAYZ/lL+PFP6QQb0=</DigestValue>
        </Reference>
      </SignedInfo>
      <SignatureValue>
        SignatureValue removed
      </SignatureValue>
      <KeyInfo>
        <X509Data>
          <X509Certificate>
            X509Certificate removed
          </X509Certificate>
          <X509IssuerSerial>
            <X509IssuerName>CN=GuideWell Mutual Holding Corporation CA, OU="Blue Cross and Blue Shield of Florida, Inc.", O=GuideWell Mutual Holding Corporation, C=US</X509IssuerName>
            <X509SerialNumber>X509SerialNumber removed</X509SerialNumber>
          </X509IssuerSerial>
        </X509Data>
      </KeyInfo>
    </Signature>
    <samlp2:Status>
      <samlp2:StatusCode Value="urn:oasis:names:tc:SAML:2.0:status:Success"/>
    </samlp2:Status>
    <saml2:Assertion Version="2.0" ID="SAML-fed8a9a6-8892-42e1-a43c-xxxxxxxxxx" IssueInstant="2021-09-24T14:42:28Z" xmlns:saml2="urn:oasis:names:tc:SAML:2.0:assertion">
      <saml2:Issuer>https://ssoportal-stga.bcbsfl.com/sso/saml2/Member/Sanitas</saml2:Issuer>
      <Signature xmlns="http://www.w3.org/2000/09/xmldsig#">
        <SignedInfo>
          <CanonicalizationMethod Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#"/>
          <SignatureMethod Algorithm="http://www.w3.org/2000/09/xmldsig#rsa-sha1"/>
          <Reference URI="#SAML-fed8a9a6-8892-42e1-a43c-xxxxxxxxxx">
            <Transforms>
              <Transform Algorithm="http://www.w3.org/2000/09/xmldsig#enveloped-signature"/>
              <Transform Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#"/>
            </Transforms>
            <DigestMethod Algorithm="http://www.w3.org/2000/09/xmldsig#sha1"/>
            <DigestValue>73RSNAXpSPRxsZ4xxxxxxxxxxx=</DigestValue>
          </Reference>
        </SignedInfo>
        <SignatureValue>SignatureValue removed</SignatureValue>
        <KeyInfo>
          <X509Data>
            <X509Certificate>
              X509Certificate removed
            </X509Certificate>
            <X509IssuerSerial>
              <X509IssuerName>CN=GuideWell Mutual Holding Corporation CA, OU="Blue Cross and Blue Shield of Florida, Inc.", O=GuideWell Mutual Holding Corporation, C=US</X509IssuerName>
              <X509SerialNumber>X509SerialNumber removed</X509SerialNumber>
            </X509IssuerSerial>
          </X509Data>
        </KeyInfo>
      </Signature>
      <saml2:Subject>
        <saml2:NameID Format="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified">rocha2020</saml2:NameID>
        <saml2:SubjectConfirmation Method="urn:oasis:names:tc:SAML:2.0:cm:bearer">
          <saml2:SubjectConfirmationData NotOnOrAfter="2021-09-24T14:52:28Z" Recipient="https://chfed.mysanitas.com/sp/ACS.saml2"/>
        </saml2:SubjectConfirmation>
      </saml2:Subject>
      <saml2:Conditions NotOnOrAfter="2021-09-24T14:52:28Z">
        <saml2:AudienceRestriction>
          <saml2:Audience>https://chfed.mysanitas.com</saml2:Audience>
        </saml2:AudienceRestriction>
        <saml2:OneTimeUse/>
      </saml2:Conditions>
      <saml2:AuthnStatement AuthnInstant="2021-09-24T14:42:28Z" SessionNotOnOrAfter="2021-09-24T14:52:28Z">
        <saml2:SubjectLocality Address="172.18.16.9"/>
        <saml2:AuthnContext>
          <saml2:AuthnContextClassRef>urn:oasis:names:tc:SAML:2.0:ac:classes:unspecified</saml2:AuthnContextClassRef>
        </saml2:AuthnContext>
      </saml2:AuthnStatement>
      <saml2:AttributeStatement>
        <saml2:Attribute Name="memberNumber" NameFormat="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified">
          <saml2:AttributeValue>[memberNumber]</saml2:AttributeValue>
        </saml2:Attribute>
        <saml2:Attribute Name="memberFirstName" NameFormat="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified">
          <saml2:AttributeValue>[memberFirstName]</saml2:AttributeValue>
        </saml2:Attribute>
        <saml2:Attribute Name="memberLastName" NameFormat="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified">
          <saml2:AttributeValue>[memberLastName]</saml2:AttributeValue>
        </saml2:Attribute>
        <saml2:Attribute Name="redirectTo" NameFormat="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified">
          <saml2:AttributeValue>https://mysanitas.com/patient/rewards</saml2:AttributeValue>
        </saml2:Attribute>
        <saml2:Attribute Name="logoutRedirectTo" NameFormat="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified">
          <saml2:AttributeValue>https://logout-stage.bcbsfl.com/logout.fcc?TARGET=https://floridablue.com</saml2:AttributeValue>
        </saml2:Attribute>
        <saml2:Attribute Name="failureRedirectTo" NameFormat="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified">
          <saml2:AttributeValue>https://www-stga.floridablue.com/member/ssoError</saml2:AttributeValue>
        </saml2:Attribute>
      </saml2:AttributeStatement>
    </saml2:Assertion>
  </samlp2:Response>
  `;

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!memberId || !memberName || !memberLastname) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      let xml = xmlTemplate
        .replace('[memberNumber]', memberId)
        .replace('[memberFirstName]', memberName)
        .replace('[memberLastName]', memberLastname);

      const base64String = xmlToBase64(xml);
      await sendData(base64String);
    }
  };

  const xmlToBase64 = (xml: string) => {
    const utf8Encoder = new TextEncoder();
    const encodedXML = utf8Encoder.encode(xml);
    return btoa(String.fromCharCode(...new Uint8Array(encodedXML)));
  };

  const sendData = async (saml: string) => {
    const url = `https://api.uat.usa.mysanitas.com/api/auth/sso`;
    const data = { SAMLResponse: saml };

    await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(data).toString(),
      // redirect: 'manual'
    }).then((response) => {
      if (response.type === "opaqueredirect") {
        saveUrl(response.url);
        window.location.href = response.url;
      }
    })
  };

  useEffect(() => {
    console.log('--nueva url- ', urlNavigation)
  }, [urlNavigation])


  return (
    <div className='generalBody'>
      <form onSubmit={handleFormSubmit}>
        <h1>SSO Form</h1>

        <label htmlFor="member_id">Member Id</label>
        <input
          type="text"
          id="member_id"
          value={memberId}
          onChange={(e) => setMemberId(e.target.value)}
        />

        <label htmlFor="member_name">Member Name</label>
        <input
          type="text"
          id="member_name"
          value={memberName}
          onChange={(e) => setMemberName(e.target.value)}
        />

        <label htmlFor="member_lastname">Member Lastname</label>
        <input
          type="text"
          id="member_lastname"
          value={memberLastname}
          onChange={(e) => setMemberLastname(e.target.value)}
        />

        <button type="submit">Enviar</button>

        {errorMessage && <p className="error">All fields are mandatory.</p>}
      </form>
    </div>
  );
};

export default FormSSO;
